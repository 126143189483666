<template>
  <div class="layout-data">
    <div class="header">
      <div class="timer">
        <span>{{ timer }}</span>
      </div>
      <div class="title"><span class="c-h" @click="$router.push('/dataV/index')">研学途迹</span></div>
      <div class="f-12 text-c c-white"><span>{{ organization.organizationName }}</span></div>
      <div class="tool title-icon">
        <Icon
          type="md-arrow-back"
          class="c-h"
          :size="22"
          @click="$router.back()"
          v-if="$route.path.includes('/dataV') && $route.query.id"
        />
        <Icon
          class="c-h tool-icon"
          custom="iconfont icon-caidan1"
          :size="22"
          @click.native="jumpConfig"
        />
        <Icon
          :type="full ? 'md-contract':'md-qr-scanner'"
          class="c-h tool-icon"
          :size="22"
          @click.native="fullScreen"
        />
        <Icon
          type="md-log-out"
          class="c-h tool-icon"
          :size="22"
          @click.native="loginOut"
        />
      </div>
    </div>
    <div class="layout-data-content">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      timer: Date.format(null, "yyyy年MM月dd日 | HH ：mm"),
      full: false,
    };
  },
  computed: {
    ...mapState({
      menu: state => state.ivew.menu,
      menus: state => state.user.menu.config,
      user: state => state.user,
      organization: state => state.user.organization
    })
  },
  methods: {
    jumpConfig() {
      this.$router.push(this.menu.list[0].children[0].fullPath)
    },
    loginOut() {
      const _this = this;
      this.$Modal.confirm({
        title: "退出",
        content: `是否退出系统？`,
        async onOk() {
             await  _this.$store.dispatch('user/getLogOut')
          // _this.$store.commit("user/USER_INFO_LOGINOUT");
          _this.$router.push({ path: "/login" });
        },
      });
    },
    fullScreen() {
      if (!this.full) {
        const el = document.documentElement;
        const rfs =
          el.requestFullScreen ||
          el.webkitRequestFullScreen ||
          el.mozRequestFullScreen ||
          el.msRequestFullscreen;
        if (typeof rfs != "undefined" && rfs) {
          rfs.call(el);
          this.full = true;
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        this.full = false;
      }
    },
  },
  mounted() {
    setInterval(
      () => (this.timer = Date.format(null, "yyyy年MM月dd日 | HH ：mm")),
      60000
    );
    
    // 获取网盘token
    let tokenNetdisk = localStorage.getItem('token_netdisk');
    if(!tokenNetdisk) {
      this.$store.dispatch('user/getAuthCode', { clientId: '1001', redirectUri: 'http://pan.xingyaedu.cn' }).then(res => {
        if(res.code == 200) {
          this.$store.dispatch('user/getTokenNetdisk', { authCode: res.data })
        }
      })
    }
    // 获取研学token
    let tokenYanxue = localStorage.getItem('token_yanxue');
    if(!tokenYanxue) {
      this.$store.dispatch('user/getAuthCode', { clientId: '1004', redirectUri: 'http://yx.xingyaedu.cn' }).then(res => {
        if(res.code == 200) {
          this.$store.dispatch('user/getTokenResearch', { authCode: res.data })
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
.layout-data {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  background: url("/static/images/frame/bg.jpg") no-repeat;
  .header {
    position: absolute;
    top: 0;
    width: 100%;
    height: 90px;
    background: url("/static/images/frame/header_bg.png") no-repeat top center;
    background-size: 80% 180%;
    z-index: 999;
    .title {
      letter-spacing: 2px;
      text-align: center;
      font-size: 32px;
      line-height: 60px;
      // color: #f2f2f2;
      letter-spacing: 3px;
      background-image: -webkit-linear-gradient(bottom, #ffffff, #eee);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .timer {
      position: absolute;
      display: flex;
      align-items: center;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      top: 40px;
      left: 20px;
    }
    .tool {
      position: absolute;
      bottom: 10px;
      right: 30px;
      .tool-icon:hover {
        color: #03a9f4;
      }
    }
    .title-icon {
      i {
        color: #fff;
        vertical-align: middle;
        width: 32px;
        height: 32px;
        line-height: 32px;
      }
    }
  }
  .layout-data-content {
    width: 100%;
    height: 100%;
  }
}
</style>
